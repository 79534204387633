import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default function createStore() {
	return new Vuex.Store({
		state: {
			host: '',
			// deviceType: 'pc',
			deviceType: 'mobile',
			adsensConfig: {},
			showDebug: false,
			firebaseConfig: {
				apiKey: 'AIzaSyBcS3cwlUXpK99s0FiNLcdhiTqTbqa8pRo',
				authDomain: 'webs-58a8d.firebaseapp.com',
				projectId: 'webs-58a8d',
				storageBucket: 'webs-58a8d.appspot.com',
				messagingSenderId: '730684174767',
				appId: '1:730684174767:web:6e76f7ee868ece100c3f5a',
				measurementId: 'G-CRPMG7Q4EJ',
			},
		},
		mutations: {
			setHost(state, host) {
				state.host = host
			},
			setTitle(state, title) {
				state.title = title
			},
			setDomain(state, domainConfig) {
				state.domainConfig = domainConfig
			},
			setDeviceType(state, deviceType) {
				state.deviceType = deviceType
			},
			setDebug(state, showDebug) {
				state.showDebug = showDebug
			},
		},
		getters: {
			showDebug: (state) => state.showDebug,
			deviceType: (state) => state.deviceType,
			host: (state) => state.host,
			adsensConfig: (state) => state.adsensConfig,
		},
	})
}
